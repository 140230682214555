.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @include media-breakpoint-down('lg') {
    flex-direction: column;
  }

  @include media-breakpoint-up('xl') {
    justify-content: center;
  }
}

.content {
  border: 1px solid var(--stroke-default);
  border-radius: $radius-8;
  box-shadow: $shadow-md;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(sm) {
    border: none;
    box-shadow: none;
    padding: 1rem;
  }

  @include media-breakpoint-up('xl') {
    display: flex;
    justify-content: center;
  }

  &-footer {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    font-size: $font-size-xs;
    font-weight: $font-weight-label;
  }
}

.logo-small {
  width: 44.67px;
  margin-top: 1.25rem;
  margin-left: 1.5rem;
}

.logo-container {
  justify-self: center;
  width: 4rem;
  height: 4rem;
  padding: .75rem;
  border-radius: $radius-8;
  border: 1px solid var(--stroke-subtle);
  box-shadow: $shadow-md;

  .logo {
    width: 100%;
    height: 100%;
  }
}

.mw-320 {
  max-width: 320px;
}

.main-heading {
  position: relative;
  z-index: 2;
}

.complete-your-profile {
  font-weight: 700;
  line-height: 1;

  @include media-breakpoint-down('xl') {
    font-size: 3.75rem;
  }

  @media (min-width: 1400px) {
    font-size: 4.875rem;
  }
}

.welcome-to-platform {
  margin-bottom: 0.5rem;
  font-weight: 700;

  @include media-breakpoint-down('xl') {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  @media (min-width: 1400px) {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

.large-screen-left-container {
  @include media-breakpoint-down('xl') {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1400px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.small-screen-top-stripe {
  height: 0.25rem;
  background-image: linear-gradient(102.02deg,
      var(--primary-strong),
      var(--primary-strong) 20%,
      var(--primary-default) 20%,
    );
  background-repeat: no-repeat;
}

@include media-breakpoint-only('md') {
  .medium-screen-top-stripe {
    display: flex;
    height: 0.5rem;
    background-image: linear-gradient(102.02deg,
        var(--primary-strong),
        var(--primary-strong) 10%,
        var(--primary-default) 10%,
        var(--primary-default) 90%,
        var(--primary-strong) 90%,
        var(--primary-strong) 100%,
      );
    background-repeat: no-repeat;
  }
}

@include media-breakpoint-only('lg') {
  .medium-screen-top-stripe {
    display: flex;
    height: 0.5rem;
    background-image: linear-gradient(102.02deg,
        var(--primary-strong) 10%,
        var(--primary-default) 10%,
        var(--primary-default) 65%,
        var(--primary-strong) 65%,
        var(--primary-strong) 75%,
        var(--warning-default) 75%,
        var(--warning-default) 75%);
    background-repeat: no-repeat;
  }
}

.extra-large-screen-top-stripe {
  display: none;
}

@include media-breakpoint-up('xl') {
  .extra-large-screen-top-stripe {
    display: flex;
    height: 0.5rem;
    background-image: linear-gradient(102.02deg,
        var(--primary-strong) 10%,
        var(--primary-default) 10%,
        var(--primary-default) 45%,
        var(--primary-strong) 45%,
        var(--primary-strong) 55%,
        var(--warning-default) 55%,
        var(--warning-default) 75%,
        var(--information-subtle) 75%,
      );
    background-repeat: no-repeat;
  }
}

.large-screen-svg-light,
.large-screen-svg-primary {
  fill: var(--bg-subtle);
  overflow: hidden;
  position: absolute;
}

.large-screen-svg-primary {
  fill: var(--primary-subtle);
}

.medium-screen-svg-light,
.medium-screen-svg-primary {
  fill: var(--bg-subtle);
  overflow: inherit;
  position: absolute;
}

.medium-screen-svg-primary {
  fill: var(--primary-subtle);
}

[dir=rtl] {

  .medium-screen-svg-light,
  .medium-screen-svg-primary,
  .large-screen-svg-light,
  .large-screen-svg-primary {
    transform: scaleX(-1);
  }
}

.small-yellow-line {
  width: 80px;
  height: 0;
  border: 2px solid var(--warning-default);
  transform: rotate(102.02deg);
}

.medium-yellow-line {
  width: 120px;
  height: 0;
  border: 3px solid var(--warning-default);
  transform: rotate(102.02deg);
}

.large-yellow-line {
  width: 240px;
  height: 0;
  border: 3px solid var(--warning-default);
  transform: rotate(102.02deg);
}

// Auth page mixins
@mixin auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

@mixin auth-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .auth-title {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .auth-subtitle {
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
    color: var(--text-default);
  }
}

@mixin auth-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@mixin auth-form-group {
  margin-bottom: 0;

  .form-group__form-field {
    margin-inline-end: 0;

    .form-control {
      border: 1px solid var(--stroke-subtle);
    }
  }
}

@mixin auth-button {
  width: 100%;
  font-size: $font-size-md;
}

// Register page styles
.register-page-container {
  @include auth-container;

  .register-title-container {
    @include auth-title-container;

    .register-title {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    .register-subtitle {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      color: var(--text-default);
    }
  }

  .register-form-container {
    @include auth-form-container;

    .register-form-group-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .register-form-group {
      @include auth-form-group;
    }

    .register-button {
      @include auth-button;
    }
  }

  .alert-content {

    .alert-heading {
      padding-top: 0.125rem;
      font-size: $font-size-md;
      font-weight: $font-weight-label;
      color: var(--text-strong);
      margin-bottom: 0.25rem;
    }

    p {
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      color: var(--text-default);
    }
  }
}

// Login page styles
.login-page-container {
  @include auth-container;

  .login-title-container {
    @include auth-title-container;

    .login-title {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    .login-subtitle {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      color: var(--text-default);
    }
  }

  .login-form-container {
    @include auth-form-container;

    .login-form-button-container {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }

    .login-form-group-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .login-form-group {
      @include auth-form-group;
    }

    .login-button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1.5rem;

      .login-forgot-password {
        font-size: $font-size-xs;
        font-weight: $font-weight-label;
        color: theme-color("primary");
        text-decoration: underline;
      }

      .login-button {
        @include auth-button;
      }
    }
  }

  .alert-content {

    .alert-heading {
      padding-top: 0.125rem;
      font-size: $font-size-md;
      font-weight: $font-weight-label;
      color: var(--text-strong);
      margin-bottom: 0.25rem;
    }

    p {
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      color: var(--text-default);
    }
  }

  .login-staff-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

    .login-staff-dashboard-text {
      font-size: $font-size-xs;
      font-weight: $font-weight-label;
      color: var(--text-default);
      margin-bottom: 0;
    }

    .login-staff-dashboard-button {
      font-weight: $font-weight-label;
    }
  }
}

// Third party auth styles
.third-party-auth-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@mixin third-party-auth-providers-container {
  display: flex;
  flex-direction: row;
  gap: .75rem;
  align-items: center;
  justify-content: center;
}

.third-party-auth-skeleton-container {
  @include third-party-auth-providers-container;

  .third-party-auth-skeleton-item {
    min-width: 25%;
    flex: 0.333;
    height: 2.5rem;
    // skeleton animation
    background-color: var(--bg-subtle);
    border-radius: $radius-8;
    animation: pulse 1s infinite;

    @keyframes pulse {
      0% {
        opacity: 0.5;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.social-auth-providers-container {
  @include third-party-auth-providers-container;

  .social-auth-provider-button {
    min-width: 25%;
    flex: 0.333;

    .btn-content {
      display: flex;
    }

    .social-auth-provider-button-icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.contentdivider-text-and-line-divider {

  &-left,
  &-right {
    height: 1px !important;
    background-color: var(--stroke-subtle) !important;
  }
}

.pgn__form-group {
  @include auth-form-group;

  margin-bottom: 1.5rem;
}

.forgot-password-back-to-login-button {
  align-self: flex-start;
}