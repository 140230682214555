.login-button-width {
    min-width: 6rem;
}

.login-page-container {
  @include auth-container;
  max-width: 100vw;
  overflow-x: hidden;

  @include media-breakpoint-down(sm) {
    padding: 2rem 0rem;
    margin: 0;
    min-height: auto;
  }

  .login-title-container {
    @include auth-title-container;
    width: 100%;
    
    @include media-breakpoint-down(sm) {
      padding: 0;
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;

      .login-title {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
      }

      .login-subtitle {
        color: $gray-500;
      }
    }
  }

  .login-form-container {
    @include auth-form-container;
    width: 100%;
    
    @include media-breakpoint-down(sm) {
      padding: 0;
      width: 100%;
      max-width: 100%;
      margin: 0;

      input {
        max-width: 100%;
      }

      .form-group {
        margin-bottom: 1rem;
      }
    }

    .login-form-button-container {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      width: 100%;
    }

    .login-form-group-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      margin-bottom: 1rem;
    }

    .login-button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      width: 100%;

      @include media-breakpoint-down(sm) {
        .login-button {
          width: 100%;
          margin-top: 0.5rem;
          height: 3rem;
          font-size: 1rem;
        }

        .login-forgot-password {
          width: 100%;
          text-align: right;
          color: $primary;
          text-decoration: none;
          font-size: 0.875rem;
        }
      }
    }
  }

  .login-staff-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

// Add styles for the divider
.content-divider {
  @include media-breakpoint-down(sm) {
    margin: 1.5rem 0;
  }
}

// Add styles for third party auth container
.third-party-auth-container {
  @include media-breakpoint-down(sm) {
    margin: 0;
    width: 100%;
    
    button {
      width: 100%;
      height: 3rem;
    }
  }
}
