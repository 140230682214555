@mixin flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

.sandbox-login {

    &__background {
        @include flex-column;
        align-items: center;
        background-color: $blue-500;
        position: relative;
        width: 100%;
    }

    &__navbar {
        position: fixed;
        margin: 2.25rem 0;
        border-radius: 3rem;
        border: 1px solid $gray-200;
        background-color: $white;
        height: 4.25rem;
        padding: 1.25rem 1.75rem;

        @media (max-width: 768px) {
            &-logo {
                height: 2rem;
            }

            margin: 1rem 0;
            height: 3.5rem;
            padding: 1rem;
        }
    }

    &__header {
        @include flex-column;
        padding: 10rem 0rem 3rem 0rem;
        gap: 1.125rem;
        width: 40rem;
        text-align: center;

        @media (max-width: 768px) {
            width: 90%;
            padding: 8rem 1rem 2rem 1rem;
        }
    }

    &__title {
        font-size: 3.125rem; // 50px
        letter-spacing: 0.02em;
        font-weight: 700;
        color: $white;
        line-height: 3.75rem; // 60px
        margin: 0;

        @media (max-width: 768px) {
            font-size: 2.25rem;
            line-height: 2.75rem;
        }
    }

    &__description {
        font-size: 1.25rem; // 20px
        line-height: 1.375rem; // 22px
        font-weight: 400;
        color: $white;
        opacity: 0.6;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }

    &__body {
        @include flex-column;
        align-items: center;
        margin: 5rem 0rem; // 6rem
        height: 35vh;
        gap: 1.25rem; // 2rem

        @media (max-width: 768px) {
            margin: 3rem 2rem;
            height: auto;
            padding: 0 1rem;
        }

        &-buttons {
            @include flex-row;
            gap: 0.78125rem; // 1.25rem

            @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
            }
        }

        &-button {
            font-size: 1.75rem; // 28px
            font-weight: 600;
            line-height: 2.125rem; // 34px
            padding: 2.875rem 4.625rem; // 46px 93px
            background-color: $gray-50;
            border-radius: 0.78125rem; // 1.25rem

            @media (max-width: 768px) {
                font-size: 1.25rem;
                padding: 1.5rem 2rem;
                width: 100%;
                text-align: center;
            }
        }

        &-note {
            font-size: 1.125rem; // 18px
            line-height: 1.375rem; // 22px
            font-weight: 400;
            color: $gray-500;

            @media (max-width: 768px) {
                font-size: 1rem;
                text-align: center;
                padding: 0 1rem;
            }
        }
    }
}